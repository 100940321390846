import React from 'react'
import SbEditable from 'storyblok-react'
import renderBloks from '@renderBloks'
import { makeStyles, Box } from '@material-ui/core'
import classNames from 'classnames'
import { monochrome } from '@helpers/palette'
import { H3, Text, MarkdownHandler } from '@system'

const Highlight = (props) => {
  const { isMobileScreenshot, _uid, title, boldTitle, link, description } =
    props.blok

  const { isConnected, highlightTitleColor, imageOnRight, variant, darkBg } =
    props

  const useStyles = makeStyles((theme) => ({
    title: {
      fontSize: '2rem',
      display: 'block',
      color:
        (!!highlightTitleColor &&
          !!highlightTitleColor.color &&
          monochrome(highlightTitleColor.color)) ||
        (darkBg && theme.palette.common.white) ||
        (isConnected && !imageOnRight && theme.palette.text.white) ||
        (variant === 'blue-background' && theme.palette.text.white) ||
        theme.palette.secondary.main,
      marginBottom: variant === 'homepage' && '8px',
    },
    description: {
      color: variant === 'homepage' ? theme.palette.primary.main : null,
      fontSize: '14px',
      marginBottom: '16px',
    },
    stat: {
      display: 'block',
      color:
        (darkBg && theme.palette.common.white) ||
        (isConnected && !imageOnRight && theme.palette.text.white) ||
        (variant === 'blue-background' && theme.palette.text.white) ||
        theme.palette.primary.main,
    },
    link: {
      // paddingTop: '16px',
      display: 'block',
      color:
        (darkBg && theme.palette.common.white) ||
        theme.palette.text[
          (isConnected && !imageOnRight && 'white') ||
            (variant === 'blue-background' && 'white') ||
            'tertiary'
        ],
      '& a': {
        display: 'block',
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.primary.main,
        borderBottom: 'none',
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: 'none',
        '&:hover': {
          borderBottom: 'none',
        },
      },
    },
    titleContainer: {
      display: 'block',
      width: '75%',
    },
    boldTitle: {
      fontWeight:
        variant === 'homepage'
          ? theme.typography.fontWeightBold
          : theme.typography.fontWeightMedium,
    },
  }))

  const classes = useStyles()

  return (
    <SbEditable content={props.blok}>
      <Box key={_uid} py={2}>
        <H3
          id={title
            .trim()
            .replace(/[^a-zA-Z0-9]/g, '-')
            .toLowerCase()}
          className={classNames(classes.title, {
            [classes.boldTitle]: boldTitle,
          })}
        >
          {title}
        </H3>
        <Box mb={2}>
          <Text
            component="div"
            className={classNames(classes.link, {
              [classes.lastLink]: variant === 'solutions' && !link.length,
            })}
          >
            {!!description && (
              <MarkdownHandler darkBg={darkBg} className={classes.description}>
                {description}
              </MarkdownHandler>
            )}
          </Text>
          {(variant === 'solutions' || variant === 'products') &&
            !!link &&
            !!link.length > 0 && (
              <Text
                className={classNames(classes.link, {
                  [classes.mobileFrameLink]: isMobileScreenshot,
                })}
              >
                {renderBloks(link)}
              </Text>
            )}
        </Box>
        {(variant === '' ||
          variant === 'blue-background' ||
          variant === 'homepage' ||
          variant === 'blue-triangle' ||
          variant === 'products-overview') &&
          !!link &&
          !!link.length > 0 &&
          renderBloks(link, classes.link)}
      </Box>
    </SbEditable>
  )
}

export default Highlight
